import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
const { persistAtom } = recoilPersist();

export const sellerChatCreateStep3Errors = atom({
  key: 'sellerChatCreateStep3Errors',
  default: {},
});

export const sellerChatCreateStep3Completed = atom({
  key: 'sellerChatCreateStep3Completed',
  default: false,
});

export const sellerChatDispatchStep3Submit = atom({
  key: 'sellerChatDispatchStep3Submit',
  default: false,
});

export const sellerChatCreateStep3SubmitValue = atom({
  key: 'sellerChatCreateStep3SubmitValue',
  default: {},
  effects_UNSTABLE: [persistAtom],
});
