import { FC, useCallback } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';

import { useWindowSize } from '@hooks_common/useWindowSize';

import { useChatRooms } from '@hooks_iqfu/chat/useChatRoom';

import chatMobileMenuHeight from '@recoil_iqfu/atoms/chat/chatMobileMenuHeight';

import { useAuth } from '@contexts_common/authContext';

import styles from './ChatMenu.module.scss';

const ChatMenu: FC = () => {
  const router = useRouter();
  const { sellerServices } = useAuth();

  const { getSellerNotificationCounts } = useChatRooms();
  const { width: windowWidth } = useWindowSize();

  const [menuHeight, setMenuHeight] = useRecoilState(chatMobileMenuHeight);

  const isInPublicPage = !router.pathname.includes('chat');

  const { data: sellerNotificationCounts } = useQuery(['sellerNotificationCounts'], () =>
    getSellerNotificationCounts(),
  );

  const setChatMobileMenuHeight = useCallback(
    (node: HTMLDivElement | null) => {
      if (node) {
        setMenuHeight(node.getBoundingClientRect().height);
      }
    },
    [windowWidth],
  );

  return (
    <div className={`${styles.container} ${isInPublicPage ? styles.isInPublicPage : ''}`} ref={setChatMobileMenuHeight}>
      <nav>
        <ul className={styles.nav_list}>
          <li className={styles.nav_item}>
            <Link
              href="/chat/talk"
              className={`${styles.chat_menu_link}  ${router.pathname.includes('talk') ? styles.is_active : ''}`}
            >
              <div className={styles.icon_wrapper}>
                <img className={styles.default_icon} src="/images/iqfu/chat/chat_menu_talk.svg" alt="" />
                <img className={styles.active_icon} src="/images/iqfu/chat/chat_menu_talk_active.svg" alt="" />
                {sellerNotificationCounts?.unread_chat_count ||
                sellerNotificationCounts?.unread_seller_financial_agency_division_room_count ||
                sellerNotificationCounts?.unread_inquiry_count ? (
                  <img className={styles.unread_icon} src="/images/iqfu/chat/unread_icon.svg" alt="" />
                ) : (
                  ''
                )}
              </div>
              <p className={styles.nav_item_text}>トーク</p>
            </Link>
          </li>
          {sellerServices && sellerServices.includes('selling') && (
            <li className={styles.nav_item}>
              <Link
                href="/chat/properties"
                className={`${styles.chat_menu_link}  ${
                  router.pathname.includes('properties') ? styles.is_active : ''
                }`}
              >
                <div className={styles.icon_wrapper}>
                  <img className={styles.default_icon} src="/images/iqfu/chat/chat_menu_properties.svg" alt="" />
                  <img className={styles.active_icon} src="/images/iqfu/chat/chat_menu_properties_active.svg" alt="" />
                </div>
                <p className={styles.nav_item_text}>査定物件</p>
              </Link>
            </li>
          )}
          <li className={styles.nav_item}>
            <Link
              href="/chat/user"
              className={`${styles.chat_menu_link}  ${router.pathname.includes('user') ? styles.is_active : ''}`}
            >
              <div className={styles.icon_wrapper}>
                <img className={styles.default_icon} src="/images/iqfu/chat/chat_menu_user.svg" alt="" />
                <img className={styles.active_icon} src="/images/iqfu/chat/chat_menu_user_active.svg" alt="" />
              </div>
              <p className={styles.nav_item_text}>ユーザー</p>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default ChatMenu;
