import { FC } from 'react';

import Link from 'next/link';

import styles from './LeaseBackCautionsDialog.module.scss';

const LeaseBackCautionsDialog: FC = () => {
  return (
    <div className={styles.dialog_inner}>
      <div className={styles.content}>
        お客様のアカウントはリースバック相談のアカウントになります。
        <br />
        不動産会社との売却相談をご希望でしたら、
        <Link href="/chat/talk?redirect=contact" className={styles.contact_link}>
          運営までご相談
        </Link>
        お願いします。
      </div>
    </div>
  );
};

export default LeaseBackCautionsDialog;
