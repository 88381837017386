import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const sellerChatCreateInConsultation = atom<boolean | null>({
  key: 'sellerChatCreateInConsultation',
  default: null,
  effects_UNSTABLE: [persistAtom],
});
