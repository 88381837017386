import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import { ChatPropertyType } from '@constants_iqfu';

const { persistAtom } = recoilPersist();

export const sellerChatCreatePropertyType = atom<keyof typeof ChatPropertyType | undefined>({
  key: 'sellerChatCreatePropertyType',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});
