import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import { ResidenceCurrentStatus } from '@constants_iqfu';

const { persistAtom } = recoilPersist();

export const sellerChatCreateBuildingCurrentStatus = atom<keyof typeof ResidenceCurrentStatus | ''>({
  key: 'sellerChatCreateBuildingCurrentStatuss',
  default: '',
  effects_UNSTABLE: [persistAtom],
});
