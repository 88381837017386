import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const sellerChatCreateBuiltYear = atom<number | undefined>({
  key: 'sellerChatCreateBuiltYear',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});
