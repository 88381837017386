import * as Sentry from '@sentry/browser';
import { useCookies } from 'react-cookie';
const backendBasePath = process.env.NEXT_PUBLIC_DEV_BACKEND_URL;

export type PageViewLogPayload = {
  sid: string; // セッションID
  path_with_query: string; // 閲覧されたページのパスとクエリ
};

export const usePageViewLogs = () => {
  const [cookies, _setCookie] = useCookies(['expiry', 'access-token', 'client', 'uid', 'user-type']);

  const createPageViewLog = async (payload: PageViewLogPayload) => {
    const url = `${backendBasePath}/logs/page_view`;

    const result = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        uid: cookies['uid'],
        client: cookies['client'],
        'access-token': cookies['access-token'],
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return response.json();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error('通信に失敗しました', error);
      });
    return result;
  };

  return {
    createPageViewLog,
  };
};
