import React, { FC, ReactElement } from 'react';

import { Breakpoint, Dialog } from '@mui/material';

import styles from './dialog_common.module.scss';

type Props = {
  children: ReactElement;
  open: boolean;
  onClose: () => void;
  maxWidth?: Breakpoint;
};

const DialogWrapper: FC<Props> = ({ children, open, onClose, maxWidth = 'lg' }) => {
  return (
    <Dialog onClose={() => onClose()} open={open} fullWidth={true} maxWidth={maxWidth} className={styles.dialog}>
      <img
        src={`/images/iqfu/chat/close_dialog.svg`}
        className={styles.close_dialog_button}
        alt=""
        onClick={() => onClose()}
      />
      {children}
    </Dialog>
  );
};

export default DialogWrapper;
