import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import { AreaUnit } from '@type-def_iqfu/UnitType';

const { persistAtom } = recoilPersist();

export const sellerChatCreateOccupiedAreaInput = atom<string>({
  key: 'sellerChatCreateOccupiedAreaInput',
  default: '',
  effects_UNSTABLE: [persistAtom],
});

export const sellerChatCreateOccupiedAreaUnit = atom<AreaUnit>({
  key: 'sellerChatCreateOccupiedAreaUnit',
  default: 'square_meter',
  effects_UNSTABLE: [persistAtom],
});

export const sellerChatCreateOccupiedAreaSubmitValue = atom<number | undefined>({
  key: 'sellerChatCreateOccupiedAreaSubmitValue',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});
