import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const sellerChatCreateSaleReasonsIdList = atom<number[]>({
  key: 'sellerChatCreateSaleReasonsIdList',
  default: [],
  effects_UNSTABLE: [persistAtom],
});
