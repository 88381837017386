import * as Sentry from '@sentry/browser';
import { useCookies } from 'react-cookie';

import { SellerCompanyRoomInfo } from '@type-def_iqfu/chat/SellerCompanyRoomInfo';
import { SellerFinancialAgencyRoomInfo } from '@type-def_iqfu/chat/SellerFinancialAgencyRoomInfo';
import { SellerNotificationCounts } from '@type-def_iqfu/chat/SellerNotificationCounts';

const queryString = require('query-string');

const backendBasePath = process.env.NEXT_PUBLIC_DEV_BACKEND_URL;

const hasValidLimitOffsetParams = (limit?: unknown, offset?: unknown) => {
  const hasValidLimit = typeof limit === 'number' && limit >= 0;
  const hasValidOffset = typeof offset === 'number' && offset >= 0;
  if ((limit && !hasValidLimit) || (offset && !hasValidOffset) || Number.isNaN(limit) || Number.isNaN(offset)) {
    return false;
  } else {
    return true;
  }
};

export const useChatRooms = () => {
  const [cookies] = useCookies(['expiry', 'access-token', 'client', 'uid', 'user-type']);

  const getChatRoomList = async (queryParams: { chat_property_id?: number; limit?: number; offset?: number }) => {
    if (!hasValidLimitOffsetParams(queryParams.limit, queryParams.offset)) return;

    const query = queryString.stringify(queryParams);
    const url = `${backendBasePath}/seller/chat_rooms?${query}`;

    console.log('getSellerCompanyChatRoomList', url);
    const result = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        uid: cookies['uid'],
        client: cookies['client'],
        'access-token': cookies['access-token'],
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error('通信に失敗しました', error);
      });
    return result;
  };

  const getChatItems = async (roomId: number, queryParams: { limit?: number; offset?: number }) => {
    if (!hasValidLimitOffsetParams(queryParams.limit, queryParams.offset)) return;

    const query = queryString.stringify(queryParams);
    const url = `${backendBasePath}/seller/chat_rooms/${roomId}/chat_items?${query}`;

    const result = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        uid: cookies['uid'],
        client: cookies['client'],
        'access-token': cookies['access-token'],
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error('通信に失敗しました', error);
      });
    return result;
  };

  const getChatFile = async (chatItemId: number, fileName: string) => {
    if (!chatItemId) return;
    const url = `${backendBasePath}/seller/chat_items/${chatItemId}/file_download`;

    const result = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        uid: cookies['uid'],
        client: cookies['client'],
        'access-token': cookies['access-token'],
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);

        let link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error('通信に失敗しました', error);
      });
    return result;
  };

  const createChatItem = async ({ roomId, submitData }: { roomId: number | undefined; submitData: FormData }) => {
    const url = `${backendBasePath}/seller/chat_rooms/${roomId}/chat_items`;
    if (!roomId) return;

    const result = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        uid: cookies['uid'],
        client: cookies['client'],
        'access-token': cookies['access-token'],
      },
      body: submitData,
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error('通信に失敗しました', error);
      });
    return result;
  };

  const deleteChatItem = async (chatItemId: number) => {
    const url = `${backendBasePath}/seller/chat_items/${chatItemId}`;

    const result = await fetch(url, {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        uid: cookies['uid'],
        client: cookies['client'],
        'access-token': cookies['access-token'],
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error('通信に失敗しました', error);
      });
    return result;
  };

  const getSellerCompanyRoomInfo = async (roomId: number): Promise<SellerCompanyRoomInfo | undefined> => {
    if (!roomId) return;

    const url = `${backendBasePath}/seller/chat_rooms/${roomId}`;
    const result = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        uid: cookies['uid'],
        client: cookies['client'],
        'access-token': cookies['access-token'],
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error('通信に失敗しました', error);
      });
    return result;
  };

  type SellerCompanyContactRoomInfo = {
    id: number;
    seller_user_id: number;
    company_id: number;
    company_display_name: string;
    company_image: string;
  };

  const getSellerCompanyContactRoomInfo = async (roomId: number): Promise<SellerCompanyContactRoomInfo | undefined> => {
    if (!roomId) return;

    const url = `${backendBasePath}/seller/chat_seller_company_rooms/${roomId}`;
    const result = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        uid: cookies['uid'],
        client: cookies['client'],
        'access-token': cookies['access-token'],
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error('通信に失敗しました', error);
      });
    return result;
  };

  const getSellerFinancialAgencyRoomInfo = async (
    roomId: number,
  ): Promise<SellerFinancialAgencyRoomInfo | undefined> => {
    if (!roomId) return;

    const url = `${backendBasePath}/seller/chat_seller_financial_agency_division_rooms/${roomId}`;
    const result = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        uid: cookies['uid'],
        client: cookies['client'],
        'access-token': cookies['access-token'],
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error('通信に失敗しました', error);
      });
    return result;
  };

  const getSellerNotificationCounts = async (): Promise<SellerNotificationCounts> => {
    const url = `${backendBasePath}/seller/dashboards/notification_counts`;

    const result = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        uid: cookies['uid'],
        client: cookies['client'],
        'access-token': cookies['access-token'],
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error('通信に失敗しました', error);
      });
    return result;
  };

  const requestSearchRecommendCompany = async () => {
    const url = `${backendBasePath}/seller/inquiry_chat_items/request_search_company`;

    const result = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        uid: cookies['uid'],
        client: cookies['client'],
        'access-token': cookies['access-token'],
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error('通信に失敗しました', error);
      });
    return result;
  };

  return {
    getChatRoomList,
    getChatItems,
    getChatFile,
    createChatItem,
    deleteChatItem,
    getSellerCompanyRoomInfo,
    getSellerCompanyContactRoomInfo,
    getSellerFinancialAgencyRoomInfo,
    getSellerNotificationCounts,
    requestSearchRecommendCompany,
  };
};
