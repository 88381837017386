import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const sellerChatCreateAddress = atom({
  key: 'sellerChatCreateAddress',
  default: '',
  effects_UNSTABLE: [persistAtom],
});

export const sellerChatCreatePrefecture = atom({
  key: 'sellerChatCreatePrefecture',
  default: '',
  effects_UNSTABLE: [persistAtom],
});

export const sellerChatCreateCity = atom({
  key: 'sellerChatCreatePropertyCity',
  default: '',
  effects_UNSTABLE: [persistAtom],
});

export const sellerChatCreateTown = atom({
  key: 'sellerChatCreateTown',
  default: '',
  effects_UNSTABLE: [persistAtom],
});
