import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const sellerChatCreateBuildingAreaInput = atom({
  key: 'sellerChatCreateBuildingAreaInput',
  default: '',
  effects_UNSTABLE: [persistAtom],
});

export const sellerChatCreateBuildingAreaUnit = atom({
  key: 'sellerChatCreateBuildingAreaUnit',
  default: 'square_meter',
  effects_UNSTABLE: [persistAtom],
});

export const sellerChatCreateBuildingAreaSubmitValue = atom({
  key: 'sellerChatCreateBuildingAreaSubmitValue',
  default: '',
  effects_UNSTABLE: [persistAtom],
});
