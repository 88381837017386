import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const sellerChatCreateMansionName = atom({
  key: 'sellerChatCreateMansionName',
  default: '',
  effects_UNSTABLE: [persistAtom],
});
