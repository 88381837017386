import { useResetRecoilState } from 'recoil';

import {
  sellerChatCreateAddress,
  sellerChatCreateCity,
  sellerChatCreatePrefecture,
  sellerChatCreateTown,
} from '@recoil_iqfu/atoms/chat/create_property/seller/address';
import {
  sellerChatCreateBuildingAreaSubmitValue,
  sellerChatCreateBuildingAreaInput,
  sellerChatCreateBuildingAreaUnit,
} from '@recoil_iqfu/atoms/chat/create_property/seller/buildingArea';
import { sellerChatCreateBuildingCurrentStatus } from '@recoil_iqfu/atoms/chat/create_property/seller/buildingCurrentStatus';
import { sellerChatCreateBuiltYear } from '@recoil_iqfu/atoms/chat/create_property/seller/builtYear';
import { sellerChatCreateFloor } from '@recoil_iqfu/atoms/chat/create_property/seller/floor';
import { sellerChatCreateFloorPlan } from '@recoil_iqfu/atoms/chat/create_property/seller/floorPlan';
import { sellerChatCreateInConsultation } from '@recoil_iqfu/atoms/chat/create_property/seller/inConsultation';
import {
  sellerChatCreateLandAreaInput,
  sellerChatCreateLandAreaUnit,
  sellerChatCreateLandAreaSubmitValue,
} from '@recoil_iqfu/atoms/chat/create_property/seller/landArea';
import { sellerChatCreateLandCurrentStatus } from '@recoil_iqfu/atoms/chat/create_property/seller/landCurrentStatus';
import { sellerChatCreateLoanBalance } from '@recoil_iqfu/atoms/chat/create_property/seller/loanBalance';
import { sellerChatCreateMansionName } from '@recoil_iqfu/atoms/chat/create_property/seller/mansionName';
import { sellerChatCreateNote } from '@recoil_iqfu/atoms/chat/create_property/seller/note';
import {
  sellerChatCreateOccupiedAreaInput,
  sellerChatCreateOccupiedAreaUnit,
  sellerChatCreateOccupiedAreaSubmitValue,
} from '@recoil_iqfu/atoms/chat/create_property/seller/occupiedArea';
import { sellerChatCreateOwnerShip } from '@recoil_iqfu/atoms/chat/create_property/seller/ownerShip';
import { sellerChatCreatePreferredTiming } from '@recoil_iqfu/atoms/chat/create_property/seller/preferredTiming';
import { sellerChatCreatePropertyType } from '@recoil_iqfu/atoms/chat/create_property/seller/propertyType';
import {
  sellerChatCreateReformTextInput,
  sellerChatCreateReformRadio,
  sellerChatCreateReformSubmitValue,
} from '@recoil_iqfu/atoms/chat/create_property/seller/reform';
import { sellerChatCreateTotalUnits } from '@recoil_iqfu/atoms/chat/create_property/seller/registerTotalUnits';
import { sellerChatCreateRoomNumber } from '@recoil_iqfu/atoms/chat/create_property/seller/roomNumber';
import { sellerChatCreateSaleReasonsIdList } from '@recoil_iqfu/atoms/chat/create_property/seller/saleReasons';
import { sellerChatCreateStep1Completed } from '@recoil_iqfu/atoms/chat/create_property/seller/step1';
import {
  sellerChatCreateStep2Errors,
  sellerChatCreateStep2Completed,
  sellerChatCreateDispatchStep2Submit,
  sellerChatCreateStep2SubmitValue,
} from '@recoil_iqfu/atoms/chat/create_property/seller/step2';
import {
  sellerChatCreateStep3Errors,
  sellerChatDispatchStep3Submit,
  sellerChatCreateStep3SubmitValue,
} from '@recoil_iqfu/atoms/chat/create_property/seller/step3';

export const useResetSellerCreateChatProperty = () => {
  const resetChatStep1Completed = useResetRecoilState(sellerChatCreateStep1Completed);
  const resetPropertyType = useResetRecoilState(sellerChatCreatePropertyType);
  const resetAddress = useResetRecoilState(sellerChatCreateAddress);
  const resetPrefecture = useResetRecoilState(sellerChatCreatePrefecture);
  const resetCity = useResetRecoilState(sellerChatCreateCity);
  const resetTown = useResetRecoilState(sellerChatCreateTown);
  const resetBuildingAreaSubmitValue = useResetRecoilState(sellerChatCreateBuildingAreaSubmitValue);
  const resetBuildingAreaInput = useResetRecoilState(sellerChatCreateBuildingAreaInput);
  const resetChatCreateBuildingAreaUnit = useResetRecoilState(sellerChatCreateBuildingAreaUnit);
  const resetChatCreateBuiltYear = useResetRecoilState(sellerChatCreateBuiltYear);
  const resetChatCreateFloor = useResetRecoilState(sellerChatCreateFloor);
  const resetChatCreateFloorPlan = useResetRecoilState(sellerChatCreateFloorPlan);

  const resetChatCreateLandAreaInput = useResetRecoilState(sellerChatCreateLandAreaInput);
  const resetChatCreateLandAreaUnit = useResetRecoilState(sellerChatCreateLandAreaUnit);
  const resetChatCreateLandAreaSubmitValue = useResetRecoilState(sellerChatCreateLandAreaSubmitValue);
  const resetChatCreateMansionName = useResetRecoilState(sellerChatCreateMansionName);
  const resetChatCreateOccupiedAreaInput = useResetRecoilState(sellerChatCreateOccupiedAreaInput);
  const resetChatCreateOccupiedAreaUnit = useResetRecoilState(sellerChatCreateOccupiedAreaUnit);
  const resetChatCreateOccupiedAreaSubmitValue = useResetRecoilState(sellerChatCreateOccupiedAreaSubmitValue);
  const resetChatCreateReformTextInput = useResetRecoilState(sellerChatCreateReformTextInput);
  const resetChatCreateReformRadio = useResetRecoilState(sellerChatCreateReformRadio);
  const resetChatCreateReformSubmitValue = useResetRecoilState(sellerChatCreateReformSubmitValue);
  const resetChatCreateTotalUnits = useResetRecoilState(sellerChatCreateTotalUnits);
  const resetChatCreateRoomNumber = useResetRecoilState(sellerChatCreateRoomNumber);
  const resetChatCreateStep2Errors = useResetRecoilState(sellerChatCreateStep2Errors);
  const resetChatCreateStep2Completed = useResetRecoilState(sellerChatCreateStep2Completed);
  const resetChatCreateDispatchStep2Submit = useResetRecoilState(sellerChatCreateDispatchStep2Submit);
  const resetChatCreateStep2SubmitValue = useResetRecoilState(sellerChatCreateStep2SubmitValue);

  const resetChatCreateBuildingCurrentStatus = useResetRecoilState(sellerChatCreateBuildingCurrentStatus);
  const resetChatCreateInConsultation = useResetRecoilState(sellerChatCreateInConsultation);
  const resetChatCreateLandCurrentStatus = useResetRecoilState(sellerChatCreateLandCurrentStatus);
  const resetChatCreateLoanBalance = useResetRecoilState(sellerChatCreateLoanBalance);
  const resetChatCreateNote = useResetRecoilState(sellerChatCreateNote);
  const resetChatCreateOwnerShip = useResetRecoilState(sellerChatCreateOwnerShip);
  const resetChatCreatePreferredTiming = useResetRecoilState(sellerChatCreatePreferredTiming);

  const resetChatCreateSaleReasonsIdList = useResetRecoilState(sellerChatCreateSaleReasonsIdList);
  const resetChatCreateStep3Errors = useResetRecoilState(sellerChatCreateStep3Errors);
  const resetChatDispatchStep3Submit = useResetRecoilState(sellerChatDispatchStep3Submit);
  const resetChatCreateStep3SubmitValue = useResetRecoilState(sellerChatCreateStep3SubmitValue);

  const resetAllCreatePropertiesState = () => {
    resetChatStep1Completed();
    resetPropertyType();
    resetAddress();
    resetPrefecture();
    resetCity();
    resetTown();
    resetBuildingAreaSubmitValue();
    resetBuildingAreaInput();
    resetChatCreateBuildingAreaUnit();
    resetChatCreateBuiltYear();
    resetChatCreateFloor();
    resetChatCreateFloorPlan();
    resetChatCreateLandAreaInput();
    resetChatCreateLandAreaUnit();
    resetChatCreateLandAreaSubmitValue();
    resetChatCreateMansionName();
    resetChatCreateOccupiedAreaInput();
    resetChatCreateOccupiedAreaUnit();
    resetChatCreateOccupiedAreaSubmitValue();
    resetChatCreateReformTextInput();
    resetChatCreateReformRadio();
    resetChatCreateReformSubmitValue();
    resetChatCreateTotalUnits();
    resetChatCreateRoomNumber();
    resetChatCreateStep2Errors();
    resetChatCreateStep2Completed();
    resetChatCreateDispatchStep2Submit();
    resetChatCreateStep2SubmitValue();
    resetChatCreateBuildingCurrentStatus();
    resetChatCreateInConsultation();
    resetChatCreateLandCurrentStatus();

    resetChatCreateLoanBalance();
    resetChatCreateNote();
    resetChatCreateOwnerShip();

    resetChatCreatePreferredTiming();
    resetChatCreateSaleReasonsIdList();
    resetChatCreateStep3Errors();

    resetChatDispatchStep3Submit();
    resetChatCreateStep3SubmitValue();
  };

  return {
    resetAllCreatePropertiesState,
  };
};
