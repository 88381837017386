import { atom } from 'recoil';

/**
 * 売主×不動産会社　不動産会社×売主　のチャットのモバイルのtalk roomにいるかのstate
 * ヘッダーとメニューが非表示になる
 * avatarのサイズが小さくなる
 */
export const isInMobileTalkRoomFlag = atom({
  key: 'isInMobileTalkRoomFlag',
  default: false,
});
