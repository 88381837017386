import { useRouter } from 'next/router';

type PagePathParams = string;

export const usePagePathParams = (): PagePathParams => {
  const router = useRouter();
  //router.asPathの / を - に変換する
  //最初の / は削除する
  // router.asPathの === / のときは 'top' にする
  // ?以後のquery parameterは落とす
  const pathParam = router.asPath === '/' ? 'top' : router.asPath.slice(1).replace(/\//g, '-').split('?')[0];

  return pathParam;
};
