import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const sellerChatCreateReformSubmitValue = atom({
  key: 'sellerChatCreateReformSubmitValue',
  default: '',
  effects_UNSTABLE: [persistAtom],
});

export const sellerChatCreateReformTextInput = atom({
  key: 'sellerChatCreateReformTextInput',
  default: '',
  effects_UNSTABLE: [persistAtom],
});

export const sellerChatCreateReformRadio = atom({
  key: 'sellerChatCreateReformRadio',
  default: '',
  effects_UNSTABLE: [persistAtom],
});
