import { FC } from 'react';

import { useSetRecoilState } from 'recoil';

import { pageLoadingAtom } from '@recoil_iqfu/atoms/public/page_loading';

type Props = {
  href: string;
  onClick?: () => void;
  target?: string;
  rel?: string;
};

const ExternalLink: FC<Props> = ({ href, onClick, target, rel, children }) => {
  const setPageLoading = useSetRecoilState(pageLoadingAtom);

  //外部リンクで、同タブで開く場合はローディングを表示する
  const handleClick = () => {
    if (target !== '_blank') {
      setPageLoading(true);
      onClick && onClick();

      //document.visibilityStateがhiddenになったらローディングを解除する
      if (!!document) {
        document.addEventListener(
          'visibilitychange',
          () => {
            if (document.visibilityState === 'hidden') {
              setPageLoading(false);
            }
          },
          { once: true },
        );
      }

      //五秒で強制的にローディングを解除する
      //なんらかの理由でローディングが解除されない場合のための保険
      setTimeout(() => {
        setPageLoading(false);
      }, 3500);
    }
  };
  return (
    <a href={href} onClick={handleClick} target={target} rel={rel}>
      {children}
    </a>
  );
};

export default ExternalLink;
