import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import { OwnerShipKeys } from '@constants_iqfu';

const { persistAtom } = recoilPersist();

export const sellerChatCreateOwnerShip = atom<OwnerShipKeys | null>({
  key: 'sellerChatCreateOwnerShip',
  default: null,
  effects_UNSTABLE: [persistAtom],
});
