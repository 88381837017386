import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import { AreaUnit } from '@type-def_iqfu/UnitType';

const { persistAtom } = recoilPersist();

export const sellerChatCreateLandAreaInput = atom<string>({
  key: 'sellerChatCreateLandAreaInput',
  default: '',
  effects_UNSTABLE: [persistAtom],
});

export const sellerChatCreateLandAreaUnit = atom<AreaUnit>({
  key: 'sellerChatCreateLandAreaUnit',
  default: 'square_meter',
  effects_UNSTABLE: [persistAtom],
});

export const sellerChatCreateLandAreaSubmitValue = atom<number | undefined>({
  key: 'sellerChatCreateLandAreaSubmitValue',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});
