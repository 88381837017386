import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const sellerChatCreateLoanBalance = atom<string>({
  key: 'sellerChatCreateLoanBalance',
  default: '',
  effects_UNSTABLE: [persistAtom],
});

export const sellerChatCreateLoanBalanceRadio = atom<'input' | 'no_loan'>({
  key: 'sellerChatCreateLoanBalanceRadio',
  default: 'input',
  effects_UNSTABLE: [persistAtom],
});

export const sellerChatCreateLoanBalanceSubmitValue = atom<number | undefined>({
  key: 'sellerChatCreateLoanBalanceSubmitValue',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});
