import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
const { persistAtom } = recoilPersist();

export const sellerChatCreateStep2Errors = atom({
  key: 'sellerChatCreateStep2Errors',
  default: {},
});

export const sellerChatCreateStep2Completed = atom({
  key: 'sellerChatCreateStep2Completed',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const sellerChatCreateDispatchStep2Submit = atom({
  key: 'sellerChatCreateDispatchStep2Submit',
  default: false,
});

export const sellerChatCreateStep2SubmitValue = atom({
  key: 'sellerChatCreateStep2SubmitValue',
  default: {},
  effects_UNSTABLE: [persistAtom],
});
