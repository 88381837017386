import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist({
  key: 'login-state',
  storage: typeof window === 'undefined' ? undefined : sessionStorage,
});

//store in session storage
export const sellerIsLoggedIn = atom({
  key: 'sellerIsLoggedIn',
  default: false,
  effects_UNSTABLE: [persistAtom],
});
