import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import { LandCurrentStatus } from '@constants_iqfu';

const { persistAtom } = recoilPersist();

export const sellerChatCreateLandCurrentStatus = atom<keyof typeof LandCurrentStatus | ''>({
  key: 'sellerChatCreateLandCurrentStatus',
  default: '',
  effects_UNSTABLE: [persistAtom],
});
